import Icon from '@mdi/react';
import {mdiTwitter, mdiFacebook, mdiInstagram, mdiMusicNote} from '@mdi/js';

const links = [
  {icon: mdiTwitter, url: 'https://twitter.com/OficialTvllanos', style: 'bg-[#00acee]'},
  {icon: mdiFacebook, url: 'https://web.facebook.com/100085591667558', style: 'bg-[#4267B2]'},
  {icon: mdiInstagram, url: 'https://instagram.com/tvllanosoficial', style: 'bg-gradient-to-r from-yellow-300 via-pink-600 to-blue-600'},
  {icon: mdiMusicNote, url: 'https://www.tiktok.com/@tvllanosoficial', style: 'bg-[#ffffff]', isImage: 'https://cdn4.iconfinder.com/data/icons/social-media-flat-7/64/Social-media_Tiktok-512.png'},
];

export default function SocialLine() {
  return (
    <div className="grid grid-cols-4">
      {links.map((it) => (
        <a href={it.url}>
          <div className={`h-20 flex justify-center items-center text-center ${it.style}`}>
            {it.isImage ? <img src={it.isImage} className="w-12 h-12" alt={it.url} /> : <Icon path={it.icon} className="w-12 h-12 text-white" />}
          </div>
        </a>
      ))}
    </div>
  )
}

import {useEffect, useState} from "react";
import Marquee from "react-fast-marquee";
import moment from "moment-timezone";

export default function EconomicIndicators() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [exchange, setExchange] = useState({
    updated_at: '',
    rate: [
      {
        id: "",
        name: "",
        value: 0,
        description: '',
        iso: '',
        symbol: '',
        formatted: '',
        color: '',
        indicator: ''
      },
      {
        id: "",
        name: "",
        value: 0,
        description: '',
        iso: '',
        symbol: '',
        formatted: '',
        color: '',
        indicator: ''
      }
    ],
  });

  useEffect(() => {
    fetch("https://us-central1-app-mone.cloudfunctions.net/app/api/exchange", {
      method: 'GET',
      redirect: 'follow'
    })
      .then(res => res.json())
      .then(res => {
        const {usd, eur} = res.rates;
        const {icon: iconUSD, updated_at} = res.indicators[0];
        const {icon: iconEUR} = res.indicators[1];
        const BCV_USD = usd.filter((it) => it.description === 'BCV')[0];
        const BCV_EUR = eur.filter((it) => it.description === 'BCV')[0];
        setExchange({
          updated_at,
          rate: [
            {
              ...iconUSD,
              ...BCV_USD,
              indicator: iconUSD.symbol,
            },
            {
              ...iconEUR,
              ...BCV_EUR,
              indicator: iconEUR.symbol,
            },
            {
              ...iconUSD,
              ...BCV_USD,
              indicator: iconUSD.symbol,
            },
            {
              ...iconEUR,
              ...BCV_EUR,
              indicator: iconEUR.symbol,
            },
            {
              ...iconUSD,
              ...BCV_USD,
              indicator: iconUSD.symbol,
            },
            {
              ...iconEUR,
              ...BCV_EUR,
              indicator: iconEUR.symbol,
            }
          ]
        });
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        console.log('EconomicIndicators on error', e);
      });
  }, [])

  if (loading || error) {
    return null;
  }

  return (
    <div className="container mx-auto my-5 xs:my-10 px-5">
      <div className="flex flex-row gap-x-2 items-center">
        <h1 className="bg-white font-bold rounded-xl px-4 py-2 mb-2">Índices económicos</h1>
      </div>
      <div className="bg-[#222222] px-4 py-2 rounded-xl">
        <Marquee gradient gradientColor={[34, 34, 34]} pauseOnHover pauseOnClick speed={40}>
          {exchange.rate.map((item, i) => (
            <div key={i} className="mx-4">
              <div className="flex flex-row gap-x-1 items-center text-2xl">
                <div className="flex flex-row items-center gap-2 mr-3">
                  <div className="text-[#999]">{item.name}</div>
                  <span className="bg-blue-800 text-xs px-1 rounded-xl text-gray-200">BCV</span>
                </div>
                <div style={{color: item.color}}>{item.indicator}</div>
                <span className="text-white">{item.formatted} {item.symbol}</span>
              </div>
            </div>
          ))}
        </Marquee>
      </div>
      <div className="mt-2 text-end text-xs text-gray-200">
        <span className="text-gray-400">Tasa de cambio según el BCV</span>{' '}
        <span>para el {moment(exchange.updated_at).tz('America/Caracas').format('lll')}</span>
      </div>
    </div>
  )
}

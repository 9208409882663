import { PhoneIcon, EnvelopeIcon, MapPinIcon } from '@heroicons/react/24/solid'

const emails = [
  { name: 'Entrevistas', mail: 'entrevistas@tvllanos.net'},
  { name: 'Programas', mail: 'programas@tvllanos.net'},
  { name: 'Comercialización', mail: 'marketing@tvllanos.net'},
]

const contacts = [
  { name: '+58 (424) 203 9417', icon: PhoneIcon, url: 'tel:+574242039417'},
  { name: '+57 (300) 332 8388', icon: PhoneIcon, url: 'tel:+573003328388'},
  { name: '+34 (604) 48 15 06', icon: PhoneIcon, url: 'tel:+34604481506'},
  { name: 'tvllanosgda@gmail.com', icon: EnvelopeIcon, url: 'mail:tvllanosgda@gmail.com'},
  { name: 'San Juan de Los Morros, Venezuela', icon: MapPinIcon, url: 'https://goo.gl/maps/HXjV8RqxcNYF22Wp7'},
]

export default function Footer() {
  return (
    <div className="bg-gradient-to-b from-[#333333] to-[#212121]">
      <div className="grid grid-cols-1 gap-0 lg:grid-cols-4 lg:gap-10">
        <div className="md:col-span-2 py-10 mx-10 md:ml-10">
          <h1 className="text-4xl font-bold text-white">Tvllanos</h1>
          <div className="mb-5 mt-2 h-[1px] w-[80%] bg-[#444444]"></div>
          <p className="text-white mb-5">Somos el canal de televisión, más moderno e innovador de Venezuela. Transmitir para usted las 24 horas del día, todos los días. En señal abierta para San Juan de Los Morros, en cable para Calabozo y Valle de La Pascua, y en internet para el resto del mundo.</p>
          <p className="text-white">Sintonízanos e interactúa con nosotros, aquí si puedes hacerlo. Somos un canal general, que transmite entretenimiento, información, cultura y actualidad desde San Juan de Los Morros, Venezuela. Y para todo el mundo, ¡somos TVLLANOS, tu canal!</p>
        </div>
        <div className="py-10 mx-10 md:ml-10">
          {emails.map((it, i) => (
            <div className={emails.length !== i - 1 ? "mb-5" : ''}>
              <h2 className="text-4xl font-bold text-white">{it.name}</h2>
              <div className="mb-1 mt-1 h-[1px] w-[80%] bg-[#444444]"></div>
              <p className="text-white">{it.mail}</p>
            </div>
          ))}
        </div>
        <div className="bg-[#212121] py-10 px-10">
          <div>
            <h2 className="text-4xl font-bold text-white">Contacto</h2>
            <div className="mb-5 mt-2 h-[1px] w-[80%] bg-[#444444]"></div>
            {contacts.map((it, i) => (
              <a href={it.url} className="text-white flex flex-row items-center mb-2">
                <it.icon className="h-5 w-5 mr-2 text-white" aria-hidden="true" /> {it.name}
              </a>
            ))}
          </div>
          <div className="mt-10">
            <p className="text-white font-light">
              <span className="font-bold">TVLLANOS &copy; {new Date().getFullYear()} </span>
              <span>️by <a href="https://www.skyzer.com.co" target="_black" className="font-semibold text-gray-400 hover:text-white">Skyzer</a></span><br/>
              Todos los derechos reservados
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

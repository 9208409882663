import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import Logo from "../Logo";

const links = [
  { name: 'En vivo', href: '#', active: true},
  { name: 'Interacciones', href: '#' },
  { name: 'Contenidos', href: '#' },
]

function Bottom({ isActive, href, name }) {
  if (isActive) {
    return (
      <a key={name} href={href} className="whitespace-nowrap capitalize text-base font-bold px-4 py-2 button bg-blue-600 cursor-pointer select-none rounded-xl">
        <span className="text-white">{name}</span>
      </a>
    );
  }

  return (
    <a key={name} href={href} className="whitespace-nowrap capitalize text-base font-bold px-4 py-2 button hover:bg-blue-800 hover:text-white cursor-pointer select-none rounded-xl transition-all duration-200">
      <span className="text-blue-50">{name}</span>
    </a>
  );
}

export default function Header() {
  return (
    <Popover className="sticky top-0 backdrop-filter backdrop-blur-lg bg-opacity-30 bg-[#333333]" style={{zIndex: 1}}>
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Logo />
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-[#333333] p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0 gap-x-2">
            {links.map(it => <Bottom isActive={it.active} name={it.name} href={it.href} />)}
          </div>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus style={{zIndex: 2}} className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
          <div className="divide-y-2 divide-gray-50 bg-[#212121] rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Logo />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-[#333333] p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-2 text-center">
                  {links.map(it => <Bottom isActive={it.active} name={it.name} href={it.href} />)}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

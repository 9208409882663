export default function Logo({ darkMode }) {
  if (darkMode) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href="/" className="font-bold flex flex-row justify-center items-center">
        <div className="rotate-[-18deg] flex justify-center items-center w-7 h-7 bg-[#333333] rounded-full">
          <span className="text-base text-center text-white">tv</span>
        </div>
        <span className="text-4xl text-[#333333]">Llanos</span>
      </a>
    )
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="/" className="font-bold flex flex-row justify-center items-center">
      <div className="rotate-[-18deg] flex justify-center items-center w-7 h-7 bg-white rounded-full">
        <span className="text-base text-center text-blue-500">tv</span>
      </div>
      <span className="text-4xl text-white">Llanos</span>
    </a>
  )
}

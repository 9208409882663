import React from "react";
import "moment/locale/es-do";

import Player from "./components/Player/Player";
import Header from "./components/Header";
import ClockOnLine from "./components/ClockOnLine";
import SocialLine from "./components/SocialLine";
import Footer from "./components/Footer";
import FormRequest from "./components/FormRequest";
import EconomicIndicators from "./components/EconomicIndicators";

export const App = () => {
  function getQuery(name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  }

  return (
    <div className="min-h-full">
      <Header />
      <div className="grid gap-y-5 grid-cols-1 px-5 xs:p-0 lg:grid-cols-3 lg:gap-5 container mx-auto my-5 xs:my-10">
        <div className="col-span-2">
          <div className="aspect-w-16 aspect-h-9">
            <Player
              isTwitch
              id="video"
              channel={getQuery('channel') || 'tvllanos'}
            />
          </div>
        </div>
        <div>
          <ClockOnLine />
          <FormRequest isTwitch channel={getQuery('channel') || 'tvllanos'} />
        </div>
      </div>
      <EconomicIndicators />
      <SocialLine />
      <Footer />
    </div>
  );
};

export default App;

import React, { useEffect, useRef } from "react";
import Clappr from "clappr";
import PlaybackRatePlugin from "clappr-playback-rate-plugin";
import ChromecastPlugin from "clappr-chromecast-plugin";
import { TwitchPlayer } from "react-twitch-embed";

const Player = ({ id, source, cover, title, channel, isTwitch }) => {
  const embed = useRef();
  let clappr_player = null;

  const handleReady = (e) => {
    embed.current = e;
  };

  useEffect(() => {
    if (!isTwitch) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clappr_player = new Clappr.Player({
        source,
        poster: cover,
        exitFullscreenOnEnd: true,
        actualLiveTime: false,
        position: 'button-left',
        parentId: `#${id}`,
        language: "es-ES",
        autoPlay: true,
        height: '100%',
        width: '100%',
        plugins: [PlaybackRatePlugin, ChromecastPlugin],
        playbackRateConfig: {
          defaultValue: 1,
          options: [
            { value: 0.2, label: "0.2x" },
            { value: 0.5, label: "0.5x" },
            { value: 1, label: "1x" },
            { value: 1.5, label: "1.5x" },
            { value: 2, label: "2x" }
          ]
        },
        chromecast: {
          appId: `SKYZER-${channel}`,
          contentType: 'application/x-mpegURL',
          media: {
            title: channel.toUpperCase(),
            type: ChromecastPlugin.Movie,
            subtitle: `${channel.toUpperCase()} - ${title}`
          },
          customData: {
            licenseURL: 'https://www.skyzer.com.co/legal'
          }
        }
      });

      clappr_player.getPlugin('markers-plugin');

      return () => {
        clappr_player.destroy();
        clappr_player = null;
      };
    }
  }, []);

  if (isTwitch) {
    return (
      <TwitchPlayer
        onReady={handleReady}
        channel={channel}
        allowFullscreen
        muted={false}
        height="100%"
        width="100%"
        playsInline
        autoplay
        id={id}
      />
    );
  }

  return <div id={id} className="w-full h-full bg-center bg-no-repeat bg-cover" />;
};

export default Player;
